export const pageTexts = (t) => {
  return {
    thankYou: {
      title: {
        text: t("contact-us.thanksTitle"),
        colorClass: "colorDark",
        extraClass: "font60 m-0 lineHeight1",
      },
      subtitle: {
        text: t("contact-us.thanksSubtitle2"),
        colorClass: "colorDark",
        extraClass: "font50 lineHeight1 fontWeight400",
      },
      text: {
        text: t("contact-us.answerSoon"),
        colorClass: "colorDark",
        extraClass: "font20 letterSpacing2",
      },
      separator: false,
    },
    backHome: {
      text: t("contact-us.backHome"),
      url: "/",
      type: "whiteColor blackBorder",
      extraClass: "bigWidth font14",
    },
    thankYouSignup: {
      title: {
        text: t("contact-us.thanksTitle"),
        colorClass: "colorDark",
        extraClass: "font60 m-0 lineHeight1",
      },
      subtitle: {
        text: t("contact-us.thanksSubtitle2"),
        colorClass: "colorDark",
        extraClass: "font50 lineHeight1 fontWeight400",
      },
      texts: [
        {
          text: t("contact-us.justEmail"),
          colorClass: "colorRed",
          extraClass: "font20 lineHeight1_3 pt-30",
          textType: "paragraph",
        },
        {
          text: t("contact-us.linkProvided"),
          colorClass: "colorDark",
          extraClass: "font20 lineHeight1_7",
          textType: "paragraph",
        },
        {
          text: t("contact-us.notReceive"),
          colorClass: "colorDark",
          extraClass: "font14 lineHeight1_7",
          textType: "paragraph",
        },
      ],
    },
  };
};
